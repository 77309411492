import { v4 } from "uuid";
import { z } from "zod";
import { postSchema } from "./post.js";
import { sessionSchema } from "./session.js";
import { contentSchema } from "./shared.js";

/**
 * A package is a collection of other items e.g., posts, sessions.
 */
export const packageSchema = contentSchema;

export type Package = z.infer<typeof packageSchema>;

/**
 * A post package is a relationship between a post and a package.
 */
export const postPackageSchema = z.object({
  /**
   * A unique identifier for the post package.
   */
  id: z.string().default(() => v4()),
  /**
   * The package id of the package.
   */
  packageId: z.string(),
  /**
   * The post id of the post.
   */
  postId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type PostPackage = z.infer<typeof postPackageSchema>;

/**
 * A session package is a relationship between a session and a package.
 */
export const sessionPackageSchema = z.object({
  /**
   * A unique identifier for the session package.
   */
  id: z.string().default(() => v4()),
  /**
   * The package id of the package.
   */
  packageId: z.string(),
  /**
   * The session id of the session.
   */
  sessionId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type SessionPackage = z.infer<typeof sessionPackageSchema>;

/**
 * A package item is a relationship between a package and another item e.g., post, session.
 *
 * Primarlity used to allow for querying of all items in a package
 */
export const packageItemSchema = z.object({
  /**
   * The id of the item e.g., postId, sessionId
   */
  itemId: z.string(),

  /**
   * The userId of the user who created the item
   */
  userId: z.string(),

  /**
   * The type of item e.g., post, session
   */
  type: z.enum(["POST", "SESSION"]),
  /**
   * The id of the package the item belongs to
   */
  packageId: z.string(),
});

export type PackageItem = z.infer<typeof packageItemSchema>;

export const packageItemWithDetail = z.object({
  id: z.string(),
  type: z.enum(["SESSION", "POST"]),
  item: z.union([sessionSchema, postSchema]),
});

export type PackageItemWithDetail = z.infer<typeof packageItemWithDetail>;
