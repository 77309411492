import { Content } from "@yoga-app/types";

export const getContentIcon = (type: Content) => {
  const icons = {
    POST: "subtitles",
    SESSION: "calendar_clock",
    PACKAGE: "package_2",
  };

  return icons[type];
};
