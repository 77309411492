import { useGetSignedUrls } from "@/hooks/media";
import { FileObject } from "@/src/types";
import { User } from "@yoga-app/types";
import { useEffect, useRef, useState } from "react";
import CustomImage from "../ui/CustomImage";
import Icon from "../ui/Icon";

interface ProfilePictureProps {
  src: string | undefined;
  user: User | undefined;
  editable?: boolean;
  onChange?: (file: FileObject[]) => void;
  styles?: string;
  avatarSize?: string;
}

const ProfilePicture = ({ src, editable, onChange, user, styles, avatarSize = "text-3xl" }: ProfilePictureProps) => {
  const [previewSrc, setPreviewSrc] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { data: signedUrls } = useGetSignedUrls(src ? [src] : []);

  useEffect(() => {
    if (signedUrls && signedUrls.length > 0) {
      setPreviewSrc(signedUrls[0].signedUrl);
      setIsLoading(false);
    } else {
      setPreviewSrc(src);
      setIsLoading(false);
    }
  }, [signedUrls, src]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileObject: FileObject = {
        file,
        id: file.name,
        preview: URL.createObjectURL(file),
        imageType: "profile",
      };
      setPreviewSrc(fileObject.preview);
      if (onChange) {
        onChange([fileObject]);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const fileObject: FileObject = {
        file,
        id: file.name,
        preview: URL.createObjectURL(file),
        imageType: "profile",
      };
      setPreviewSrc(fileObject.preview);
      if (onChange) {
        onChange([fileObject]);
      }
      event.dataTransfer.clearData();
    }
  };

  const handleImageError = () => {
    setPreviewSrc(undefined);
  };

  return (
    <div
      className={`relative ${styles} ${editable ? "cursor-pointer" : ""}`}
      onDrop={editable ? handleDrop : undefined}
      onDragOver={editable ? (e) => e.preventDefault() : undefined}
      onClick={editable ? () => fileInputRef.current?.click() : undefined}
    >
      <div className="relative w-full h-full rounded-full overflow-hidden shadow flex items-center justify-center bg-gray-700 text-accent-content">
        {isLoading ? (
          <div className="w-full h-full skeleton"></div>
        ) : previewSrc ? (
          <div className="w-full h-full aspect-[1/1]">
            <CustomImage
              src={previewSrc}
              alt="Profile"
              className="w-full h-full object-cover"
              onError={handleImageError}
            />
          </div>
        ) : (
          <div className={`${avatarSize} font-bold`}>{user?.username.charAt(0).toUpperCase()}</div>
        )}
      </div>
      {editable && (
        <>
          <div
            className="absolute top-2 right-3 bg-gray-200 hover:bg-gray-500 rounded-full px-2 py-1 cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            <Icon name="edit" color="#FF724C" size={18} fill />
          </div>
          <input type="file" ref={fileInputRef} className="hidden" accept="image/*" onChange={handleFileChange} />
        </>
      )}
    </div>
  );
};

export default ProfilePicture;
