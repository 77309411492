import { useAuth } from "@/hooks/auth";
import config from "@/src/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetUser } from "../../hooks/user";
import NotificationList from "../notification/NotificationLIst";
import ProfilePicture from "../profile/ProfilePicture";
import Icon from "../ui/Icon";

// Pages to exclude navbar links
const excludeNavBarLinks = ["/sign-in", "/sign-up"];

const Navbar = ({ onBurgerClick }: { onBurgerClick: () => void }) => {
  const location = useLocation();
  const { signOut, isLoading, isAuthenticated, getUserId } = useAuth();
  const navigate = useNavigate();
  const userId = getUserId() as string;
  const { data: user } = useGetUser(userId);

  const showBurgerIcon = location.pathname.startsWith("/dashboard");

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  return (
    <div className="navbar sticky inset-x-0 top-0 z-50 h-16 w-full bg-base-100">
      <header className="relative w-full">
        <div className="w-full mx-1 lg:mx-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              {showBurgerIcon && (
                <button onClick={onBurgerClick} className="btn btn-ghost md:hidden pr-0">
                  <Icon name="menu" color="black" />
                </button>
              )}
              <Link to={"/"} className={`btn btn-ghost text-accent text-xl font-roboto ${!showBurgerIcon && "ml-2"}`}>
                bodhiga
              </Link>
            </div>

            {/* {!excludeNavBarLinks.includes(location.pathname) && (
              <div className="flex-grow flex items-center justify-center">
                {isLoading ? null : isAuthenticated && <SearchBar />}
              </div>
            )} */}

            {!excludeNavBarLinks.includes(location.pathname) && (
              <div className="flex items-center space-x-6">
                {isLoading ? null : isAuthenticated ? (
                  // user logged in
                  <>
                    {/* Notifications */}
                    <NotificationList userId={userId} />

                    {/* Add content */}
                    <button
                      className="btn btn-primary font-semibold text-gray-50 h-8 w-6 min-h-0 min-w-0 rounded-full items-center flex"
                      onClick={() => navigate(`/dashboard/content#create`)}
                    >
                      <Icon name="add" size={22} color="white" />
                    </button>

                    {/* User dropdown */}
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-circle">
                        <ProfilePicture
                          user={user}
                          src={user?.profilePic?.[150]}
                          styles={"w-12 h-12"}
                          avatarSize="text-sm"
                        />
                      </label>
                      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-neutral-100 rounded-box w-52">
                        <li>
                          <Link to={`/${user?.username}`} className="btn btn-ghost text-md hover:bg-gray-300">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/profile#monetization`}
                            className="btn btn-ghost text-md hover:bg-gray-300"
                          >
                            Monetization
                          </Link>
                        </li>
                        <li>
                          <button className="btn btn-ghost text-md hover:bg-gray-300" onClick={handleSignOut}>
                            Sign out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  // user not logged in
                  <>
                    {/* Menu dropdown on smaller screens */}
                    <div className="dropdown dropdown-end lg:hidden">
                      <label tabIndex={0} className="btn btn-ghost">
                        <Icon name="menu" color="black" />
                      </label>
                      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                            Sign in
                          </button>
                        </li>
                        {config.app.stage !== "production" && (
                          <li>
                            <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                              Create account
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                    {/* Menu items on larger screens */}
                    <div className="hidden lg:flex lg:items-center lg:space-x-4">
                      <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                        Sign in
                      </button>
                      {config.app.stage !== "production" && (
                        <>
                          <div className="divider divider-horizontal"></div>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                            Create account
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
