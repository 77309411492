interface ActionButtonProps {
  text: string;
  icon?: React.ReactNode;
  className?: string;
  onClick: () => void;
  disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, icon, className = "", onClick, disabled }) => {
  return (
    <button
      className={`flex items-center space-x-0.5 border rounded-lg px-2 py-1 text-gray-600 text-sm font-bold hover:bg-gray-100 hover:shadow-sm select-none focus:outline-none ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};

export default ActionButton;
