import { trpc } from "@/utils/trpc";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "../utils/postHelpers";

export const useCreateUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.createUser.useMutation({
    onSuccess: async () => {
      await utils.user.getAllUsers.invalidate();
    },
  });
};

export const useGetUserQuery = () => {
  const utils = trpc.useUtils();

  return (userId: string) => utils.user.getUser.fetch({ userId });
};

export const useGetUser = (userId: string) => {
  return trpc.user.getUser.useQuery(
    { userId: userId },
    {
      enabled: !!userId,
    },
  );
};

export const useGetUserByUsername = (username: string) => {
  return trpc.user.getUserByUsername.useQuery(
    { username },
    {
      enabled: !!username,
    },
  );
};

export const useGetAllUsers = () => {
  const utils = trpc.useUtils();
  return useQuery(["allUsers"], () => utils.client.user.getAllUsers.query({ fields: ["id", "username"] }));
};

export const useUpdateUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.updateUser.useMutation({
    onSuccess: async (_, input) => {
      await utils.user.getUser.invalidate({ userId: input.id });
    },
  });
};

export const useFollowUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.followUser.useMutation({
    onSuccess: async (_, input) => {
      await Promise.all([
        utils.user.getUser.invalidate({ userId: input.userId }),
        utils.user.getUser.invalidate({ userId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.userId }),
      ]);
    },
  });
};

export const useUnfollowUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.unfollowUser.useMutation({
    onSuccess: async (_, input) => {
      await Promise.all([
        utils.user.getUser.invalidate({ userId: input.userId }),
        utils.user.getUser.invalidate({ userId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.userId }),
      ]);
    },
  });
};

interface UseFollowProps {
  userId: string;
  followingUserId: string;
}

export const useFollow = ({ userId, followingUserId }: UseFollowProps) => {
  const followUserMutation = useFollowUserMutation();
  const unfollowUserMutation = useUnfollowUserMutation();
  const { data, isLoading: isCheckLoading } = useCheckFollowStatus(followingUserId);

  const [following, setFollowing] = useState<boolean | undefined>();

  useEffect(() => {
    if (data) {
      setFollowing(data.isFollowing);
    }
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFollowClick = useCallback(
    debounce(async () => {
      if (following === undefined) return;

      try {
        if (following) {
          await unfollowUserMutation.mutateAsync({ userId, followingUserId });
        } else {
          await followUserMutation.mutateAsync({ userId, followingUserId });
        }
        setFollowing((prev) => !prev);
      } catch (error) {
        console.error("Failed to toggle follow:", error);
      }
    }, 300),
    [following, followUserMutation, unfollowUserMutation, userId, followingUserId],
  );

  return {
    following,
    isLoading: isCheckLoading,
    handleFollowClick,
  };
};

export const useCheckFollowStatus = (followingUserId: string) => {
  return trpc.user.checkFollowStatus.useQuery(
    { followingUserId },
    {
      enabled: !!followingUserId,
    },
  );
};
