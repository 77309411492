import { formatDateRange, formatDuration } from "@/src/utils/date";
import { getContentIcon } from "@/src/utils/icon";
import { getContentPageLink } from "@/src/utils/navigation";
import { Package, Post, Session, User } from "@yoga-app/types";
import { Link } from "react-router-dom";
import PackageItemList from "../../package/packageItem/PackageItemList";
import Icon from "../../ui/Icon";
import MediaCarousel from "../../ui/MediaCarousel";

interface ContentContentProps {
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  content: Post | Session | Package;
  user: User;
  isPaid: boolean;
}

const ContentContent = ({ content, user, isPaid }: ContentContentProps) => {
  const isMediaContent = content.entities && content.entities.length > 0;
  const isSession = content.type === "SESSION";
  const isPackage = content.type === "PACKAGE";
  const session = isSession ? (content as Session) : undefined;

  return (
    <div className="block p-1 px-2 pb-0">
      {isSession && session && <SessionDetail session={session} isPaid={isPaid} />}

      {isPackage && <PackageItemList packageId={content.id} />}

      {(content.text || content.shortText) && (
        <Link to={getContentPageLink(user.username, content.id, content.type)}>
          <div
            className="prose max-w-full p-2 pt-0 font-roboto"
            dangerouslySetInnerHTML={{ __html: (content.text || content.shortText) as string }}
          ></div>
        </Link>
      )}

      {isMediaContent && (
        <div className="w-full aspect-[1/1] p-4">
          <MediaCarousel entities={content.entities!} />
        </div>
      )}
    </div>
  );
};

export default ContentContent;

interface SessionDetailProps {
  session: Session;
  isPaid: boolean;
}

const SessionDetail = ({ session, isPaid }: SessionDetailProps) => {
  /**
   * This is overall questionable, I've added some logic in as to whether to show the participant count.
   * The intention here was to avoid showing 0/10 participants which might discourage people from joining.
   *
   * if maxParticipantCount is not available we always show it
   *
   */
  //Get the percentage of participants
  const participantPercentage =
    session && session.maxParticipantCount ? (session.participantCount / session.maxParticipantCount) * 100 : 0;
  // if less than a certain amount of participants, show the participant count regardless of percentage
  const shouldShowParticipantCount = session && session.maxParticipantCount ? participantPercentage >= 25 : true;

  return (
    <div className="relative mb-4 p-4 rounded-lg bg-transparent border-[1px]">
      <Icon name={getContentIcon(session.type)} color="#32325d" size={20} className="absolute top-2 right-2" />

      <div className="flex flex-col">
        <div className="grid grid-cols-[auto_1fr] gap-2">
          {/* Location */}
          <div className="flex flex-row">
            {" "}
            {session.locationType === "online" ? (
              <Icon name="computer" color="#9ca3af" size={16} className="mr-1" />
            ) : (
              <Icon name="location_on" color="#9ca3af" size={16} className="mr-1" />
            )}
            <div className="text-gray-400 text-sm ml-1">Location</div>
          </div>

          <div className="text-gray-800 text-sm truncate">
            {session.locationType === "online" ? "Online" : "In-Person"}
          </div>

          {/* Address (only for in-person sessions) */}
          {session.locationType === "in-person" && (
            <>
              <div className="flex flex-row">
                {" "}
                <Icon name="location_on" color="#9ca3af" size={16} className="mr-1" />
                <div className="text-gray-400 text-sm ml-1">Address</div>
              </div>

              <div className="text-gray-800 text-sm truncate">
                {[
                  session.addressLine1,
                  session.addressLine2,
                  session.city,
                  session.state,
                  session.postalCode,
                  session.country,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </div>
            </>
          )}

          {/* URL (only for paid online sessions) */}
          {session.locationType === "online" && isPaid && session.locationUrl && (
            <>
              <div className="flex items-center space-x-2">
                <Icon name="content_copy" color="#9ca3af" size={16} />
                <div className="text-gray-400 text-sm">URL</div>
              </div>

              <div className="text-gray-800 text-sm truncate">
                <a
                  href={session.locationUrl} //Trying to open the link in a new tab with bodhiga url first / url
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {session.locationUrl}
                </a>
              </div>
            </>
          )}

          {/* Date & Time */}
          <div className="flex flex-row">
            {" "}
            <Icon name="calendar_today" color="#9ca3af" size={16} className="mr-1" />
            <div className="text-gray-400 text-sm ml-1">Date</div>
          </div>

          <div className="text-gray-800 text-sm truncate">
            {formatDateRange(session.startDateTime, session.endDateTime)}
          </div>

          {/* Duration */}
          <div className="flex flex-row">
            {" "}
            <Icon name="schedule" color="#9ca3af" size={16} className="mr-1" />
            <div className="text-gray-400 text-sm ml-1">Duration</div>
          </div>

          <div className="text-gray-800 text-sm truncate">{formatDuration(session.duration)}</div>

          {/* Participant Count (if applicable) */}
          {shouldShowParticipantCount && (
            <>
              <div className="flex flex-row">
                <Icon name="group" color="#9ca3af" size={16} className="mr-1" />
                <div className="text-gray-400 text-sm ml-1">Participants</div>
              </div>

              <div className="text-gray-800 text-sm truncate">
                {session.participantCount} / {session.maxParticipantCount}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
