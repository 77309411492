// AdminDashboardLayout.tsx
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const AdminDashboardLayout: React.FC = () => {
  return (
    <div className="flex h-full">
      <AdminSidebar />
      <div className="flex-grow flex flex-col bg-base-100 overflow-y-scroll overflow-x-hidden p-4">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboardLayout;

const AdminSidebar: React.FC = () => {
  return (
    <div className="bg-gradient-to-t from-neutral from-90% to-base-100 text-gray-700 w-56 flex-col p-4 hidden md:flex h-full">
      <nav className="flex flex-col space-y-2 mt-10 ml-4">
        <NavLink
          to="/admin/reported-content"
          className={({ isActive }) =>
            `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
          }
        >
          Reported Content
        </NavLink>
      </nav>
    </div>
  );
};
