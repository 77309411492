import { useGetNotifications } from "@/hooks/notification";
import { useState } from "react";
import Icon from "../ui/Icon";
import InfiniteList from "../ui/InfiniteList";
import NotificationCard from "./NotificationCard";

interface NotificationsListProps {
  userId: string;
}

const NotificationList: React.FC<NotificationsListProps> = ({ userId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useGetNotifications(userId, {
    limit: 10,
  });

  const notifications = data?.pages.flatMap((page) => page.items) || [];
  const unreadCount = notifications.filter((n) => n.status === "UNREAD").length;

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="dropdown dropdown-end Z-50">
      {/* Notifications Bell Icon */}
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost h-8 w-6 min-h-0 min-w-0 rounded-full items-center hidden md:flex"
        onClick={toggleDropdown}
      >
        <div className="indicator">
          <Icon name="notifications" size={30} color="#f59e0b" fill />
          {unreadCount > 0 && (
            <span className="indicator-item badge badge-error text-white w-1 min-w-0 text-xs">{unreadCount}</span>
          )}
        </div>
      </div>

      {/* Dropdown notifications cards */}
      <ul
        tabIndex={0}
        className={`dropdown-content menu z-50 bg-white shadow-lg rounded-lg w-80 p-4 space-y-2 ${isOpen ? "block" : "hidden"}`}
      >
        {isLoading ? (
          <div className="flex items-center justify-center h-40">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : notifications.length > 0 ? (
          <InfiniteList
            data={notifications}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage || false}
            isFetching={isFetching}
            CardComponent={({ item }) => <NotificationCard notification={item} />}
            direction="vertical"
          />
        ) : (
          <p className="text-sm text-gray-500">No new notifications</p>
        )}
      </ul>
    </div>
  );
};

export default NotificationList;
