import { useCallback, useMemo, useRef } from "react";
import { v4 } from "uuid";

interface InfiniteListProps<T> {
  data: T[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  CardComponent: React.ComponentType<{ item: T }>;
  isFetching: boolean;
  direction?: "vertical" | "horizontal";
  className?: string;
}

const InfiniteList = <T extends { id: string }>({
  data,
  fetchNextPage,
  hasNextPage,
  isFetching,
  CardComponent,
  direction = "vertical",
  className = "",
}: InfiniteListProps<T>) => {
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (!data.length) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage && !isFetching) {
            fetchNextPage();
          }
        },
        { threshold: 0.25 },
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, data.length],
  );

  // Memoize the data to avoid re-rendering
  const items = useMemo(() => (data ? [...data] : []), [data]);

  return (
    <div
      className={`flex no-scrollbar ${
        direction === "vertical" ? "flex-col space-y-2" : "flex-row space-x-2"
      } w-full ${className}`}
    >
      {items.map((item, index) => (
        <div
          ref={index === items.length - 1 ? lastElementRef : null}
          key={`${v4()}-${item.id}`}
          className={direction === "vertical" ? "w-full" : "h-full"}
        >
          <CardComponent item={item} />
        </div>
      ))}
      {(hasNextPage || isFetching) && (
        <div className={`flex justify-center items-center mt-4 ${direction === "horizontal" ? "ml-4" : ""}`}>
          <span className="loading loading-spinner loading-md"></span>
        </div>
      )}
    </div>
  );
};

export default InfiniteList;
