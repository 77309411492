import { useMutation } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import { Package, PackageItemWithDetail } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useCreatePackageMutation = () => {
  const { getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  return useMutation(
    async (packageData: { package: Package; items?: { id: string; type: "POST" | "SESSION" }[] }) => {
      const response = await request<{ packageId: string }>(`${config.api.url}/package`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(packageData),
      });

      if (!response) {
        throw new Error("Failed to create package");
      }

      return response;
    },
    {
      onSuccess: async (_, variables) => {
        const { userId } = variables.package;

        // Invalidate caches to reflect new package creation
        await utils.dashboard.getPackages.invalidate();
        await utils.package.getPackagesByUser.invalidate({ userId });
        await utils.content.getContentByUser.invalidate({ userId });
      },
      onError: (error) => {
        console.error("Error creating package:", error);
      },
    },
  );
};

export const useGetPackages = (userId: string, pageOptions: PageOptions) => {
  return trpc.package.getPackagesByUser.useQuery({
    userId: userId,
    pageOptions: {
      limit: pageOptions.limit,
      cursor: pageOptions.cursor,
    },
  });
};

export const useGetPackageItems = (
  packageId: string,
  initialItems: PackageItemWithDetail[],
  pageOptions: PageOptions,
) => {
  return trpc.package.getPackageItems.useInfiniteQuery(
    {
      packageId: packageId,
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: { pages: [{ items: initialItems, nextCursor: pageOptions.cursor }], pageParams: [undefined, 1] },
    },
  );
};

export const useGetPackage = (username: string, packageId: string) => {
  return trpc.package.getPackage.useQuery(
    {
      username: username,
      packageId: packageId,
    },
    {
      enabled: !!username && !!packageId,
    },
  );
};
