export const formatDate = (date: Date, format: string): string => {
  const options: Intl.DateTimeFormatOptions = {};

  if (format.includes("YYYY")) options.year = "numeric";
  if (format.includes("MMMM")) options.month = "long";
  if (format.includes("MMM")) options.month = "short";
  if (format.includes("DD")) options.day = "2-digit";
  if (format.includes("HH")) options.hour = "2-digit";
  if (format.includes("mm")) options.minute = "2-digit";

  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const formatHumanReadableDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Ensure date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-GB", options);
};

export const formatDateRange = (startDate: string, endDate: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short", // Short month format (e.g., "Sep")
    year: "numeric", // Include the year
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format with AM/PM
  };

  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", options);

  // Using formatRange to generate a concise date range
  return dateTimeFormat.formatRange(new Date(startDate), new Date(endDate));
};

/**
 * Converts a date string into a human-readable "time since" format.
 *
 * @returns {string} A human-readable string representing the time elapsed since the provided date.
 * For example, "1 second ago", "3 minutes ago", "5 hours ago", "2 days ago", "1 month ago", or "2 years ago".
 *
 * @example
 * // Assuming the current date is 2024-08-04T12:34:56Z
 * const createdAt = "2023-08-01T12:34:56Z";
 * console.log(timeSince(createdAt)); // Outputs: "1 year ago"
 */
export const formatTimeSinceDate = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000); // Seconds in a year
  if (interval >= 1) {
    return interval === 1 ? "1 year ago" : `${interval} years ago`;
  }

  interval = Math.floor(seconds / 2592000); // Seconds in a month
  if (interval >= 1) {
    return interval === 1 ? "1 month ago" : `${interval} months ago`;
  }

  interval = Math.floor(seconds / 86400); // Seconds in a day
  if (interval >= 1) {
    return interval === 1 ? "1 day ago" : `${interval} days ago`;
  }

  interval = Math.floor(seconds / 3600); // Seconds in an hour
  if (interval >= 1) {
    return interval === 1 ? "1 hour ago" : `${interval} hours ago`;
  }

  interval = Math.floor(seconds / 60); // Seconds in a minute
  if (interval >= 1) {
    return interval === 1 ? "1 minute ago" : `${interval} minutes ago`;
  }

  return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
};

/**
 * Converts a given duration in minutes into a human-readable format.
 * The function handles conversion to minutes, hours, days, weeks, and months.
 *
 * @param {number} duration - The duration in minutes.
 * @return {string} A string representing the duration in the largest possible unit.
 *
 * The function performs the following conversions:
 * - If duration is less than 60, it returns the duration in minutes.
 * - If duration is less than 1440 (24 hours), it returns the duration in hours.
 * - If duration is less than 10080 (7 days), it returns the duration in days.
 * - If duration is less than 43200 (30 days), it returns the duration in weeks.
 * - Otherwise, it returns the duration in months.
 */
export const formatDuration = (duration: number) => {
  const pluralize = (value: number, unit: string) => (value === 1 ? `${value} ${unit}` : `${value} ${unit}s`);

  if (duration < 60) return pluralize(duration, "min");
  if (duration < 1440) return pluralize(Math.floor(duration / 60), "hour");
  if (duration < 10080) return pluralize(Math.floor(duration / 1440), "day");
  if (duration < 43200) return pluralize(Math.floor(duration / 10080), "week");
  return pluralize(Math.floor(duration / 43200), "month");
};
