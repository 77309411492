import { z } from "zod";
import { contentSchema } from "./shared.js";

export const locationUrlSchema = z.enum(["online", "in-person"], {
  errorMap: () => ({ message: "Location type is required" }),
});
export type LocationUrl = z.infer<typeof locationUrlSchema>;

/**
 * A session can be thought of as a class,session, event, workshop, webinar. Its a specific activity designed for participation
 */
export const sessionSchema = contentSchema.extend({
  /**
   * Duration of the session in minutes
   */
  duration: z.number({
    required_error: "Duration is required",
    invalid_type_error: "Duration must be a number",
  }),
  /**
   * Start Date and time of the session
   */
  startDateTime: z
    .string({
      required_error: "Start date and time is required",
      invalid_type_error: "Start date and time must be a string",
    })
    .datetime({ offset: true, message: "Invalid start date and time format" }),
  /**
   * End Date and time of the session
   */
  endDateTime: z
    .string({
      required_error: "End date and time is required",
      invalid_type_error: "End date and time must be a string",
    })
    .datetime({ offset: true, message: "Invalid end date and time format" }),

  /**
   * Number of participants in the session
   */
  participantCount: z.number(),

  /**
   * Maximum number of participants allowed
   */
  maxParticipantCount: z
    .number({
      required_error: "Max Participants is required",
      invalid_type_error: "Max Participants must be a number",
    })
    .optional(),
  /**
   * Location type for the session
   */
  locationType: locationUrlSchema,
  /**
   * Location URL for online sessions
   */
  locationUrl: z
    .string()
    .regex(/[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
      message: "Invalid URL format",
    })
    .optional()
    .or(z.literal("")),
  /**
   * Address line 1 for in-person sessions
   */
  addressLine1: z.string().optional(),
  /**
   * Address line 2 for in-person sessions
   */
  addressLine2: z.string().optional(),
  /**
   * City for in-person sessions
   */
  city: z.string().optional(),
  /**
   * State for in-person sessions
   */
  state: z.string().optional(),
  /**
   * Postal code for in-person sessions
   */
  postalCode: z.string().optional(),
  /**
   * Country for in-person sessions
   */
  country: z.string().optional(),
});

export type Session = z.infer<typeof sessionSchema>;

/**
 * user session is the relationship between a user and a session. When a user purchases / attends a session, a user session record is created
 */
export const userSessionSchema = z.object({
  /**
   * The user id of the user who is participating in the session.
   */
  userId: z.string(),
  /**
   * The session id of the session.
   */
  sessionId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type UserSession = z.infer<typeof userSessionSchema>;
