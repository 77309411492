import { useGetSignedUrls } from "@/hooks/media";
import { getImageEntityKeys } from "@/src/utils/media";
import { Entity, isImageEntity } from "@yoga-app/types";
import { useState } from "react";
import CustomImage from "./CustomImage";

const MediaCarousel = ({ entities }: { entities: Entity[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const imageEntityKeys = getImageEntityKeys(entities);
  const { data: signedUrls = [], isLoading } = useGetSignedUrls(imageEntityKeys);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? entities.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === entities.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="relative w-full h-full">
      <div className="carousel w-full h-full overflow-hidden relative">
        {entities.map((entity, index) => (
          <div
            key={entity.id}
            id={entity.id}
            className={`carousel-item w-full h-full absolute inset-0 transition-opacity ${
              index === currentIndex ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
          >
            {isImageEntity(entity) && !isLoading && (
              <CustomImage
                src={signedUrls.find((url) => url.key === entity.url?.[800])?.signedUrl || ""}
                alt={entity.type}
                className="aspect-[1/1] object-cover rounded-lg"
              />
            )}
          </div>
        ))}
      </div>

      {entities.length > 1 && (
        <>
          {/* Prev/Next Controls */}
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-4 py-2 rounded-full hover:bg-gray-800 z-20"
            onClick={prevSlide}
          >
            ❮
          </button>
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-4 py-2 rounded-full hover:bg-gray-800 z-20"
            onClick={nextSlide}
          >
            ❯
          </button>

          {/* Slide Indicators */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-20">
            {entities.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === currentIndex ? "bg-secondary" : "bg-gray-400 hover:bg-gray-500"
                }`}
                onClick={() => setCurrentIndex(index)}
              ></button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MediaCarousel;
