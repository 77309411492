import { cognitoClient } from "@/src/authConfig";
import config from "@/src/config";
import {
  AuthFlowType,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  GetUserCommand,
  InitiateAuthCommand,
  ResendConfirmationCodeCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import React, { createContext, useEffect, useMemo, useState } from "react";

const getErrorMessage = (error: unknown): string => {
  switch ((error as Error)?.name) {
    case "CodeDeliveryFailureException":
      return "Failed to deliver verification code.";
    case "ForbiddenException":
      return "Action forbidden by AWS WAF.";
    case "InternalErrorException":
      return "An internal error occurred. Please try again later.";
    case "InvalidEmailRoleAccessPolicyException":
      return "Email identity not allowed. Please contact support.";
    case "InvalidLambdaResponseException":
      return "Invalid response from AWS Lambda.";
    case "InvalidParameterException":
      return "Invalid parameter provided.";
    case "InvalidSmsRoleAccessPolicyException":
      return "SMS role does not have permission to publish using Amazon SNS.";
    case "InvalidSmsRoleTrustRelationshipException":
      return "Invalid trust relationship for SMS role.";
    case "LimitExceededException":
      return "Request limit exceeded. Please try again later.";
    case "NotAuthorizedException":
      return "Not authorized to perform this action.";
    case "ResourceNotFoundException":
      return "Requested resource not found.";
    case "TooManyRequestsException":
      return "Too many requests. Please try again later.";
    case "UserLambdaValidationException":
      return "User validation failed.";
    case "UserNotFoundException":
      return "User not found.";
    case "CodeMismatchException":
      return "The provided code does not match.";
    case "ExpiredCodeException":
      return "The code has expired.";
    case "InvalidPasswordException":
      return "The provided password is invalid.";
    case "TooManyFailedAttemptsException":
      return "Too many failed attempts. Please try again later.";
    case "UserNotConfirmedException":
      return "User not confirmed.";
    case "InvalidUserPoolConfigurationException":
      return "User pool configuration is not valid.";
    case "PasswordResetRequiredException":
      return "Password reset required.";
    case "UsernameExistsException":
      return "Email is already taken";
    case "AccessDeniedException":
      return "You do not have sufficient access to perform this action.";
    case "IncompleteSignature":
      return "The request signature does not conform to AWS standards.";
    case "InternalFailure":
      return "The request processing has failed because of an unknown error, exception or failure.";
    case "InvalidAction":
      return "The action or operation requested is invalid. Verify that the action is typed correctly.";
    case "InvalidClientTokenId":
      return "The X.509 certificate or AWS access key ID provided does not exist in our records.";
    case "NotAuthorized":
      return "You do not have permission to perform this action.";
    case "OptInRequired":
      return "The AWS access key ID needs a subscription for the service.";
    case "RequestExpired":
      return "The request reached the service more than 15 minutes after the date stamp on the request or more than 15 minutes after the request expiration date (such as for pre-signed URLs), or the date stamp on the request is more than 15 minutes in the future.";
    case "ServiceUnavailable":
      return "The request has failed due to a temporary failure of the server.";
    case "ThrottlingException":
      return "The request was denied due to request throttling.";
    case "ValidationError":
      return "The input fails to satisfy the constraints specified by an AWS service.";

    default:
      return "An unknown error occurred. Please try again.";
  }
};

export interface AuthContextType {
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => Promise<Result>;
  signUp: (email: string, password: string, username: string) => Promise<Result>;
  confirmSignup: (username: string, code: string) => Promise<Result>;
  resendConfirmationCode: (email: string) => Promise<Result>;
  signOut: () => void;
  forgotPassword: (email: string) => Promise<Result>;
  confirmPassword: (email: string, code: string, newPassword: string) => Promise<Result>;
  getAccessToken: () => string | undefined;
  getRefreshToken: () => string | undefined;
  getUsername: () => string | undefined;
  getUserId: () => string | undefined;
  getUserEmail: () => string | undefined;
  refreshSession: (refreshToken: string) => Promise<{
    idToken: string | undefined;
    accessToken: string | undefined;
  }>;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (!accessToken && !refreshToken) {
      setIsLoading(false);
      return;
    }

    const checkAuth = async () => {
      try {
        await getUserOrRefresh(accessToken, refreshToken);
        setIsAuthenticated(true);
      } catch (_) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    void checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserOrRefresh = async (accessToken: string | undefined, refreshToken: string | undefined) => {
    if (accessToken) {
      try {
        const user = await getUser(accessToken);
        if (user) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if ((error as Error)?.name === "NotAuthorizedException" && refreshToken) {
          await refreshSession(refreshToken);
        } else {
          throw error;
        }
      }
    } else if (refreshToken) {
      await refreshSession(refreshToken);
    } else {
      throw new Error("No access token or refresh token found");
    }
  };

  const getUser = async (accessToken: string) => {
    const getUserCommand = new GetUserCommand({ AccessToken: accessToken });
    const response = await cognitoClient.send(getUserCommand);

    if (response.UserAttributes) {
      const subAttribute = response.UserAttributes.find((attr) => attr.Name === "sub");
      const usernameAttribute = response.UserAttributes.find((attr) => attr.Name === "name");
      const emailAttribute = response.UserAttributes.find((attr) => attr.Name === "email");

      if (subAttribute && subAttribute.Value) {
        localStorage.setItem("sub", subAttribute.Value);
      }
      if (usernameAttribute && usernameAttribute.Value) {
        localStorage.setItem("username", usernameAttribute.Value);
      }
      if (emailAttribute && emailAttribute.Value) {
        localStorage.setItem("email", emailAttribute.Value);
      }
    }

    return response;
  };

  const refreshSession = async (refreshToken: string) => {
    const refreshCommand = new InitiateAuthCommand({
      AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
      ClientId: config.auth.userPoolClientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    });
    const response = await cognitoClient.send(refreshCommand);
    if (response.AuthenticationResult) {
      const { IdToken, AccessToken, RefreshToken } = response.AuthenticationResult;
      localStorage.setItem("idToken", IdToken!);
      localStorage.setItem("accessToken", AccessToken!);
      if (RefreshToken) {
        localStorage.setItem("refreshToken", RefreshToken);
      }
      // Fetch user info
      await getUser(AccessToken!);
      return { idToken: IdToken, accessToken: AccessToken };
    } else {
      throw new Error("Failed to refresh session");
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      const initiateAuthCommand = new InitiateAuthCommand({
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        ClientId: config.auth.userPoolClientId,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
      });
      const response = await cognitoClient.send(initiateAuthCommand);

      if (response.AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } = response.AuthenticationResult;
        localStorage.setItem("idToken", IdToken!);
        localStorage.setItem("accessToken", AccessToken!);
        localStorage.setItem("refreshToken", RefreshToken!);

        await getUser(AccessToken!);

        setIsAuthenticated(true);
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          message: "Failed to Sign In. Please try again.",
        };
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return {
        success: false,
        message: errorMessage,
      };
    }
  };

  const signUp = async (email: string, password: string, username: string) => {
    const signUpCommand = new SignUpCommand({
      ClientId: config.auth.userPoolClientId,
      Username: email,
      Password: password,
      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
        {
          Name: "name",
          Value: username,
        },
      ],
    });

    try {
      const response = await cognitoClient.send(signUpCommand);

      if (response.UserSub) {
        localStorage.setItem("sub", response.UserSub);
      }

      return { success: true, message: "" };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return {
        success: false,
        message: errorMessage,
      };
    }
  };

  const confirmSignup = async (username: string, code: string) => {
    const command = new ConfirmSignUpCommand({
      ClientId: config.auth.userPoolClientId,
      Username: username,
      ConfirmationCode: code,
    });

    try {
      await cognitoClient.send(command);
      return { success: true, message: "Signup confirmed successfully" };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return { success: false, message: errorMessage };
    }
  };

  const resendConfirmationCode = async (email: string): Promise<Result> => {
    const command = new ResendConfirmationCodeCommand({
      ClientId: config.auth.userPoolClientId,
      Username: email,
    });

    try {
      await cognitoClient.send(command);
      return { success: true, message: "A new confirmation code has been sent to your email." };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return { success: false, message: errorMessage };
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("idToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("sub");
      localStorage.removeItem("username");
      localStorage.removeItem("email");

      setIsAuthenticated(false);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return {
        success: false,
        message: errorMessage,
      };
    }
  };

  const forgotPassword = async (email: string) => {
    const command = new ForgotPasswordCommand({
      ClientId: config.auth.userPoolClientId,
      Username: email,
    });

    try {
      await cognitoClient.send(command);
      return { success: true, message: "Password reset code sent" };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return { success: false, message: errorMessage };
    }
  };

  const confirmPassword = async (email: string, code: string, newPassword: string) => {
    const command = new ConfirmForgotPasswordCommand({
      ClientId: config.auth.userPoolClientId,
      Username: email,
      ConfirmationCode: code,
      Password: newPassword,
    });

    try {
      await cognitoClient.send(command);
      return { success: true, message: "Password reset successfully" };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return { success: false, message: errorMessage };
    }
  };

  const getAccessToken = () => {
    return localStorage.getItem("accessToken") ?? undefined;
  };

  const getRefreshToken = () => {
    return localStorage.getItem("refreshToken") ?? undefined;
  };

  const getUsername = () => {
    return localStorage.getItem("username") ?? undefined;
  };

  const getUserId = () => {
    return localStorage.getItem("sub") ?? undefined;
  };

  const getUserEmail = () => {
    return localStorage.getItem("email") ?? undefined;
  };

  const value = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      signIn,
      signUp,
      confirmSignup,
      resendConfirmationCode,
      signOut,
      forgotPassword,
      confirmPassword,
      getAccessToken,
      getRefreshToken,
      getUsername,
      getUserId,
      getUserEmail,
      refreshSession,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, isLoading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
