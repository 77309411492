import { z } from "zod";
import { contentSchema } from "./shared.js";

/**
 * A post is essentially a container that can include text and media content.
 */
export const postSchema = contentSchema
  //Ensures that every post must contain either text or at least one entity (e.g., image, video).
  .refine((data) => data.text || (data.entities && data.entities.length > 0), {
    message: "Posts must contain content. Either text or entities.",
  })
  // Ensure entity positions are unique and sequential
  .refine(
    (data) => {
      if (data.entities) {
        const positions = data.entities.map((entity) => entity.position);
        const uniquePositions = new Set(positions);
        const isSequential = positions.sort((a, b) => a - b).every((value, index) => value === index);
        return uniquePositions.size === positions.length && isSequential;
      }
      return true;
    },
    {
      message: "Entities must have unique and sequential positions starting from 0.",
    },
  );

export type Post = z.infer<typeof postSchema>;

/**
 * A pricing plan defines the cost and access rules for content.
 */
export const pricingPlanSchema = z
  .object({
    /**
     * A unique identifier for the pricing plan.
     * UUID v4
     */
    id: z.string(),

    /**
     * The user id of the user who created the pricing plan.
     */
    userId: z.string(),

    /**
     * Pricing model - 'one-time', 'subscription'
     */
    model: z.enum(["one-time", "subscription"]),

    /**
     * Price amount if applicable
     */
    amount: z.number().optional(),

    /**
     * Duration for subscription (e.g., 'monthly', 'yearly')
     */
    duration: z.string().optional(),

    /**
     * Access scope for the payment (e.g., '30 classes', 'premium content')
     */
    accessScope: z.string().optional(),

    /**
     * Discount details if applicable
     */
    discounts: z
      .array(
        z.object({
          percentage: z.number().optional(),
          fixedAmount: z.number().optional(),
          code: z.string().optional(),
        }),
      )
      .optional(),
  })
  .refine((data) => (data.model === "subscription" ? !!data.duration : true));

export type PricingPlan = z.infer<typeof pricingPlanSchema>;
