const Icon = ({
  name,
  size = 24,
  color = "black",
  fill = false,
  className = "",
}: {
  name: string;
  size?: number;
  color?: string;
  fill?: boolean;
  className?: string;
}) => {
  return (
    <span
      style={{
        fontFamily: "Material Symbols Outlined",
        fontSize: `${size}px`,
        color: color,
      }}
      aria-hidden="true"
      className={`material-symbols-${fill ? "filled" : "outlined"} inline-block align-middle ${className}`}
    >
      {name}
    </span>
  );
};

export default Icon;
