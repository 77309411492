import { PageOptions } from "@yoga-app/db-utils";
import { trpc } from "../utils/trpc";

export const useGetNotifications = (userId: string, pageOptions: PageOptions) => {
  return trpc.notification.queryNotificationsByUser.useInfiniteQuery(
    {
      userId,
      limit: pageOptions.limit,
    },
    {
      enabled: !!userId,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: { pages: [{ items: [], nextCursor: pageOptions.cursor }], pageParams: [undefined, 1] },
      staleTime: 300000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useUpdateNotificationStatus = () => {
  const utils = trpc.useUtils();
  return trpc.notification.updateNotificationStatus.useMutation({
    onSuccess: async (_, input) => {
      await utils.notification.queryNotificationsByUser.invalidate({ userId: input.userId }, { exact: false });
    },
  });
};
