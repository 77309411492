import { useQuery } from "@tanstack/react-query";
import { ContentItemUri } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useGetPayment = (contentItemUri: ContentItemUri) => {
  return trpc.payment.getPayment.useQuery(
    { contentItemUri },
    {
      refetchInterval: 1000 * 60 * 1, // 1 minute
    },
  );
};

export const useStripeConnectedAccountStatus = () => {
  const { getUserId, getAccessToken } = useAuth();
  const userId = getUserId() as string;
  return useQuery(
    ["accountStatus", userId],
    () => {
      return request<{ verified: boolean; newAccount: boolean }>(`${config.api.url}/payment/check-account-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ userId }),
      });
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  );
};
