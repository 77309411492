// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => void>(func: T, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    // Use Parameters<T> to infer argument types
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
