import { z } from "zod";
import { imageSizesSchema } from "./shared.js";

export const userSchema = z.object({
  /**
   * A unique identifier for the user.
   *
   * Cognito Auth Sub
   */
  id: z.string(),

  /**
   * The user's username.
   */
  username: z.string(),

  /**
   * The user's email address.
   */
  email: z.string().email(),

  /**
   * The user's bio.
   */
  bio: z.string().optional(),

  /**
   * The user's profile picture with sizes.
   *
   * e.g., { "150": "/user-uploads/user123/150.webp", "400": "/user-uploads/user123/400.webp" }
   */
  profilePic: imageSizesSchema.optional(),

  /**
   * The user's follower count.
   */
  followerCount: z.number().default(0),

  /**
   * The user's following count.
   */
  followingCount: z.number().default(0),

  /**
   * The users stripe connected account id. When creating a stripe connected account, you're registering
   * a user with Stripe so they can receive payments.
   * We'll get this when a user wants to start receiving payments.
   */
  stripeAccountId: z.string().optional(),

  /**
   * Admin status.
   *
   * Manage content, ect.
   */
  admin: z.boolean().optional(),

  /**
   * The date and time the post was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the post was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type User = z.infer<typeof userSchema>;

export const followSchema = z.object({
  /**
   * The user id of the user who is following.
   */
  userId: z.string(),

  /**
   * The user id of the user being followed.
   */
  followingUserId: z.string(),

  /**
   * The date and time the post was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the post was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});
