// src/components/NotificationCard.tsx
import { useUpdateNotificationStatus } from "@/hooks/notification";
import { getNotificationUrl } from "@/utils/navigation";

import { Notification } from "@yoga-app/types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation

interface NotificationCardProps {
  notification: Notification;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  const navigate = useNavigate();
  const updateNotificationStatus = useUpdateNotificationStatus();
  const [loading, setLoading] = useState(false); // Local loading state

  const handleNotificationClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // If the notification is unread, update its status to read
      if (notification.status === "UNREAD") {
        await updateNotificationStatus.mutateAsync({
          notificationId: notification.id,
          notificationCreatedAt: notification.createdAt,
          userId: notification.toUserId,
          status: "READ",
        });
      }

      // Navigate to the target URL
      const url = getNotificationUrl(notification);
      if (!url) return; // Return early if the URL is null this really shouldn't happen as far as I can tell
      navigate(url);
    } catch (error) {
      console.error("Failed to update notification status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <li
      className={`relative p-4 cursor-pointer rounded-lg transition-color items-start hover:bg-gray-100 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
      onClick={!loading ? handleNotificationClick : undefined}
    >
      {/* Notification status indicator */}
      <div className="absolute top-2 right-2">
        {notification.status === "UNREAD" ? (
          <span className="badge badge-primary badge-xs" title="Unread"></span>
        ) : null}
      </div>
      <div className="flex flex-col space-y-1 hover:bg-transparent items-start">
        <p className="text-sm font-semibold">{notification.message}</p>
        <p className="text-xs text-gray-500">{new Date(notification.createdAt).toLocaleString()}</p>
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 rounded-lg">
          {/* Loading Spinner */}
          <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-500"></div>
        </div>
      )}
    </li>
  );
};

export default NotificationCard;
