import { z } from "zod";
import { contentItemUri } from "./shared.js";

export const discoverySchema = z.object({
  contentItemUri: contentItemUri,
  userId: z.string(),
  /**
   * The date and time the hashtag was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the hashtag was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type Discovery = z.infer<typeof discoverySchema>;
