import { FileObject } from "@/src/types";
import { convertToBase64 } from "@/utils/media";
import { useMutation } from "@tanstack/react-query";
import { Entity, ImageSizes } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

interface UploadResponse {
  files: ImageSizes[];
}

export const useFileUpload = () => {
  const { getUserId, getAccessToken } = useAuth();

  const uploadFiles = async (files: FileObject[]): Promise<Entity[]> => {
    if (!files.length) return [];

    const userId = getUserId();
    const fileData = await Promise.all(
      files.map(async (file) => {
        const base64File = await convertToBase64(file.file);
        return {
          content: base64File,
          type: file.file.type,
          imageType: file.imageType,
        };
      }),
    );

    try {
      const response = await request<UploadResponse>(`${config.api.url}/media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          files: fileData,
          userId,
        }),
      });

      return response.files.map((imageSizes, index) => {
        const isImage = files[index].file.type.startsWith("image/");

        return {
          id: files[index].id,
          type: isImage ? "image" : "video",
          url: imageSizes,
          position: index,
        };
      });
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };

  const { mutateAsync } = useMutation(uploadFiles);

  return {
    uploadFiles: mutateAsync,
  };
};

export const useGetSignedUrls = (keys: string[]) => {
  const expiresIn = 3600 * 1000;
  const cacheTime = expiresIn * 2;

  return trpc.media.generateSignedUrls.useQuery(
    { keys },
    {
      enabled: keys.length > 0,
      cacheTime: cacheTime,
      staleTime: expiresIn,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
};
