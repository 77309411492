import { PageOptions } from "@yoga-app/db-utils";
import { trpc } from "../utils/trpc";

export const useGetContent = (userId: string, pageOptions: PageOptions) => {
  return trpc.content.getContentByUser.useInfiniteQuery(
    {
      userId: userId,
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: { pages: [{ items: [], nextCursor: pageOptions.cursor }], pageParams: [undefined, 1] },
    },
  );
};

export const useReportContent = () => {
  return trpc.content.reportContent.useMutation();
};

export const useGetReportedContentByUser = (pageOptions: PageOptions) => {
  return trpc.content.getReportedContentByUser.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: { pages: [{ items: [], nextCursor: pageOptions.cursor }], pageParams: [undefined, 1] },
    },
  );
};

export const useGetAllReportedContent = (pageOptions: PageOptions) => {
  return trpc.content.getAllReportedContent.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialData: { pages: [{ items: [], nextCursor: pageOptions.cursor }], pageParams: [undefined, 1] },
    },
  );
};

export const useApproveReportedContent = () => {
  const utils = trpc.useUtils();

  return trpc.content.approveReportedContent.useMutation({
    onSuccess: () => {
      void utils.content.getAllReportedContent.invalidate();
    },
  });
};
