import { Fragment, ReactNode, useEffect, useState } from "react";
import Icon from "./Icon";

interface TableProps<T> {
  columns: { header: string; accessor: keyof T }[];
  data: T[];
  pageLimit: number;
  pageLimitOptions?: number[];
  onPageChange?: (pageLimit: number) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  selectableRows?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  expandedRowComponent?: (data: T) => ReactNode;
}

const Table = <T extends { id: string }>({
  columns,
  data,
  pageLimit,
  pageLimitOptions,
  onPageChange,
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isFetchingNextPage,
  isFetchingPreviousPage,
  selectableRows = false,
  onSelectedRowsChange,
  expandedRowComponent,
}: TableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const handleSelectRow = (id: string) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      }
      return [...prevSelectedRows, id];
    });
  };

  const handleToggleExpand = (rowId: string) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedRows);
    }
  }, [selectedRows, onSelectedRowsChange]);

  const isReactNode = (value: unknown): value is ReactNode => {
    return typeof value === "object" || typeof value === "function";
  };

  return (
    <div className="overflow-x-auto">
      <table className="table w-full">
        <thead>
          <tr>
            {selectableRows && <th></th>}
            {columns.map((column) => (
              <th key={column.header}>{column.header}</th>
            ))}
            {expandedRowComponent && <th>Expand</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <Fragment key={row.id}>
              <tr>
                {selectableRows && (
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-sm checkbox-primary [--chkfg:white]"
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    </label>
                  </td>
                )}
                {columns.map((column) => (
                  <td key={String(column.accessor)} className="text-xs sm:text-sm">
                    {typeof row[column.accessor] === "boolean" ? (
                      <Icon
                        name={row[column.accessor] ? "check_circle" : "cancel"}
                        color={row[column.accessor] ? "#24b47e" : "red"}
                      />
                    ) : isReactNode(row[column.accessor] as unknown) ? (
                      (row[column.accessor] as ReactNode)
                    ) : (
                      String(row[column.accessor])
                    )}
                  </td>
                ))}
                {expandedRowComponent && (
                  <td>
                    <button onClick={() => handleToggleExpand(row.id)} className="flex items-center space-x-1">
                      <span className={expandedRow === row.id ? "text-primary" : "text-gray-400 text-xs"}>preview</span>
                      <Icon
                        name="keyboard_arrow_down"
                        size={20}
                        color={expandedRow === row.id ? "text-primary" : "text-gray-500"}
                        className={expandedRow === row.id ? "text-primary" : "text-gray-500"}
                      />
                    </button>
                  </td>
                )}
              </tr>
              {expandedRow === row.id && expandedRowComponent && (
                <tr>
                  <td colSpan={columns.length + (selectableRows ? 2 : 1)}>
                    <div className="p-4 bg-gray-100 rounded-lg">{expandedRowComponent(row)}</div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        {pageLimitOptions && (
          <div>
            <label className="mr-2">Items per page:</label>
            <select
              value={pageLimit}
              onChange={(e) => {
                if (onPageChange) {
                  onPageChange(Number(e.target.value));
                }
              }}
              className="border rounded p-1"
            >
              {pageLimitOptions.map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="btn-group">
          <button
            className={`btn ${!hasPreviousPage ? "btn-disabled" : ""}`}
            onClick={onPreviousPage}
            disabled={!hasPreviousPage || isFetchingPreviousPage}
          >
            {isFetchingPreviousPage ? (
              <span className="loading loading-spinner loading-sm text-gray-500"></span>
            ) : (
              "Previous"
            )}
          </button>
          <button
            className={`btn ${!hasNextPage ? "btn-disabled" : ""}`}
            onClick={onNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? <span className="loading loading-spinner loading-sm text-gray-500"></span> : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
