interface LoadingProps {
  className?: string;
}

const Loading = ({ className }: LoadingProps) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  );
};

export default Loading;
