import { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardSideDrawer from "./DashboardSideDrawer";
import Navbar from "./Navbar";

export default function RootLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = () => setDrawerOpen(false);

  return (
    <div className={`relative h-[calc(100vh-90px)] font-roboto antialiased font-normal font-sm`}>
      <main className="relative flex flex-col h-screen">
        <Navbar onBurgerClick={() => setDrawerOpen(true)} />

        {/* Dashboard Drawer for mobile view */}
        {drawerOpen && <DashboardSideDrawer closeDrawer={closeDrawer} />}

        <div className="flex-grow flex-1 bg-base-100 no-scrollbar overflow-y-scroll overflow-x-hidden" id="detail">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
