import { TRPCClientError } from "@trpc/client";
import { AppRouter } from "@yoga-app/trpc";
import createHttpError from "http-errors";

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

// Type guard to check if error is HttpError
export function isHttpError(error: unknown): error is createHttpError.HttpError {
  return error instanceof createHttpError.HttpError;
}

export const request = async <TResponse>(url: string, config: RequestInit): Promise<TResponse> => {
  const response = await fetch(url, config);
  return (await response.json()) as TResponse;
};
