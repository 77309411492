import { NavLink } from "react-router-dom";
import Icon from "../ui/Icon";

const DashboardSideDrawer = ({ closeDrawer }: { closeDrawer: () => void }) => {
  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="fixed inset-0 bg-black opacity-50" onClick={closeDrawer}></div>

      <div className="relative bg-white w-64 h-full flex flex-col shadow-lg transform transition-transform duration-300 ease-in-out">
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center space-x-2">
            <Icon name="store" size={24} color="black" />
            <span className="text-lg font-bold font-roboto">bodhiga</span>
          </div>
          <button className="btn btn-ghost" onClick={closeDrawer}>
            <Icon name="close" size={24} color="black" />
          </button>
        </div>
        <nav className="flex flex-col space-y-2 mt-4 ml-4 z-50">
          <NavLink
            to="/dashboard/profile"
            className={({ isActive }) =>
              `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
            }
            onClick={closeDrawer}
          >
            Profile
          </NavLink>
          <NavLink
            to="/dashboard/content"
            className={({ isActive }) =>
              `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
            }
            onClick={closeDrawer}
          >
            Content
          </NavLink>
          <NavLink
            to="/dashboard/purchases"
            className={({ isActive }) =>
              `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
            }
            onClick={closeDrawer}
          >
            Purchases
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default DashboardSideDrawer;
