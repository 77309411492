import { useStripeConnectedAccountStatus } from "@/hooks/payment";
import { Link, NavLink, Outlet } from "react-router-dom";
import Icon from "../ui/Icon";

const DashboardLayout = () => {
  const { data: stripeConnectedAccountStatus } = useStripeConnectedAccountStatus();
  return (
    <div className="flex h-full">
      <Sidebar />
      <div
        className="flex-grow flex flex-col bg-base-100 no-scrollbar overflow-y-scroll overflow-x-hidden p-4"
        id="detail"
      >
        {stripeConnectedAccountStatus?.newAccount === true && (
          <div className="pb-4">
            <Banner
              message="Start earning money by monetizing your content."
              linkText="Complete Verification"
              linkTo={`/dashboard/profile#monetization`}
            />
          </div>
        )}
        {stripeConnectedAccountStatus?.newAccount === false && stripeConnectedAccountStatus?.verified === false && (
          <div className="pb-4">
            <Banner
              message="Your account requires further verification to continue."
              linkText="Complete Verification"
              linkTo={`/dashboard/profile#monetization`}
            />
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;

const Sidebar = () => {
  return (
    <div className="bg-gradient-to-t from-neutral from-90% to-base-100 text-gray-700 w-56 flex-col p-4 hidden md:flex h-full">
      <nav className="flex flex-col space-y-2 mt-10 ml-4">
        <NavLink
          to={`/dashboard/profile`}
          className={({ isActive }) =>
            `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
          }
        >
          Profile
        </NavLink>

        <NavLink
          to={`/dashboard/content`}
          className={({ isActive }) =>
            `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
          }
        >
          Content
        </NavLink>

        <NavLink
          to={`/dashboard/purchases`}
          className={({ isActive }) =>
            `hover:bg-neutral-200 py-2 px-3 rounded text-sm ${isActive ? "font-bold text-primary" : ""}`
          }
        >
          Purchases
        </NavLink>
      </nav>
    </div>
  );
};

interface BannerProps {
  message: string;
  linkText: string;
  linkTo: string;
}

const Banner = ({ message, linkTo, linkText }: BannerProps) => {
  return (
    <Link to={linkTo} className="no-underline">
      <div className="bg-primary text-white flex justify-between items-center p-3 rounded-md shadow-lg hover:bg-primary-dark cursor-pointer">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-bold">{message}</span>
          <span className="text-sm underline">{linkText}</span>
          <Icon name="chevron_right" size={18} color="white" />
        </div>
      </div>
    </Link>
  );
};
