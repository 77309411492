import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { Suspense, lazy, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary";
import ProtectedAdminRoute from "./components/admin/ProtectedAdminRoute";
import AdminDashboardLayout from "./components/admin/layout/AdminDashboardLayout";
import DashboardLayout from "./components/layout/DashboardLayout";
import RootLayout from "./components/layout/RootLayout";
import Loading from "./components/ui/Loading";
import { useAuth } from "./hooks/auth";
import ReportedContent from "./pages/admin/ReportedContent";
import CookiePolicy from "./pages/support/legal/CookiePolicy";
import PrivacyPolicy from "./pages/support/legal/PrivacyPolicy";
import TermsOfService from "./pages/support/legal/TermsOfService";
import { trpc, useTrpcClient } from "./utils/trpc";

// Lazy load the page components
const Home = lazy(() => import("./pages/Home"));
const Profile = lazy(() => import("./pages/Profile"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const Signin = lazy(() => import("./pages/auth/Signin"));
const Signup = lazy(() => import("./pages/auth/Signup"));
const ConfirmSignup = lazy(() => import("./pages/auth/ConfirmSignup"));
const Post = lazy(() => import("./pages/content/Post"));
const Package = lazy(() => import("./pages/content/Package"));
const Session = lazy(() => import("./pages/content/Session"));
const DashboardProfile = lazy(() => import("./pages/dashboard/Profile"));
const Purchases = lazy(() => import("./pages/dashboard/Purchases"));
const Community = lazy(() => import("./pages/Community"));
const Content = lazy(() => import("./pages/dashboard/Content"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <GlobalErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/dashboard",
        element: <DashboardLayout />,
        children: [
          {
            path: "profile",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <DashboardProfile />
              </Suspense>
            ),
          },
          {
            path: "purchases",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <Purchases />
              </Suspense>
            ),
          },
          {
            path: "content",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <Content />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "sign-in",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signin />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signup />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: "confirm-signup",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ConfirmSignup />
          </Suspense>
        ),
      },
      {
        path: "admin",
        element: (
          <ProtectedAdminRoute>
            <Suspense fallback={<Loading className="h-screen" />}>
              <AdminDashboardLayout />
            </Suspense>
          </ProtectedAdminRoute>
        ),
        children: [
          {
            path: "reported-content",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <ReportedContent />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "terms-of-service",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <TermsOfService />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "cookie-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <CookiePolicy />
          </Suspense>
        ),
      },
      {
        path: "community/:hashtag",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Community />
          </Suspense>
        ),
      },
      {
        path: ":username/post/:postId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Post />
          </Suspense>
        ),
      },
      {
        path: ":username/package/:packageId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Package />
          </Suspense>
        ),
      },
      {
        path: ":username/session/:sessionId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Session />
          </Suspense>
        ),
      },

      {
        path: ":username",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Profile />
          </Suspense>
        ),
      },
    ],
  },
]);

export function App() {
  const { refreshSession, getRefreshToken, signOut } = useAuth();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: async (error: unknown) => {
              if ((error as Error).message && (error as Error).message.includes("Unable to transform response")) {
                try {
                  const refreshToken = getRefreshToken();

                  if (!refreshToken) {
                    signOut();
                    return;
                  }
                  await refreshSession(refreshToken);
                  await queryClient.refetchQueries();
                } catch (e) {
                  console.error("Session refresh failed", e);
                  signOut();
                }
              }
            },
          },
        },
      }),
  );

  const trpcClient = useTrpcClient();

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  void persistQueryClient({
    queryClient,
    persister,
    maxAge: Infinity,
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
